<template>
  <dx-util-popup
      ref="moveInventoryRef"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="false"
      width="95vw"
      height="95vh"
      :title="moveInventoryPopupTitle"
      @shown="onShownMoveInventoryPopup"
      @hidden="onHiddenMoveInventoryPopup"
    >
      <template>
        <div>
          <dx-util-validation-group class="">
            <div class="d-flex bg-light-primary p-1 align-items-center">
              <dx-util-select-box v-model="selectedStore"
                :data-source="stores.filter(el => el.value !== 0)" class="mx-1"
                value-expr="value" display-expr="text"
                placeholder="Select Store Destination"
              >
                <dx-util-validator>
                  <dx-util-required-rule message="Please select a destination store." />
                  <dx-util-range-rule :min="1" message="Please select a destination store." />
                </dx-util-validator>
              </dx-util-select-box>
              <dx-util-select-box v-model="selectedItemAction"
                :data-source="itemActions" class="mx-1"
                value-expr="value" display-expr="text"
                placeholder="Select Item Action"
              >
                <dx-util-validator>
                  <dx-util-required-rule message="Please select an item action." />
                </dx-util-validator>
              </dx-util-select-box>
              <dx-util-select-box v-model="selectedMoveReason"
                :data-source="inventoryMoveReasons" class="mx-1"
                value-expr="value" display-expr="text"
                placeholder="Move Reason"
              >
                <dx-util-validator>
                  <dx-util-required-rule message="Please select an inventory move reason." />
                </dx-util-validator>
              </dx-util-select-box>
              <dx-util-select-box v-model="selectedUserPlan"
                :data-source="userPlans" width="450"
                display-expr="text" value-expr="value"
              >
                <dx-util-validator>
                  <dx-util-required-rule message="Please select a plan." />
                </dx-util-validator>
              </dx-util-select-box>
              <div class="flex-grow-1"></div>
              <div class="px-1 d-flex">
                <dx-util-button type="success" text="Move" :on-click="onSubmit" class="mr-1" style="min-width: 100px;" />
                <dx-util-button type="danger" text="Cancel" :on-click="onClose" style="min-width: 100px;" />
              </div>
            </div>
          </dx-util-validation-group>
          <dx-data-grid
            id="moveInventoryGrid"
            ref="moveInventoryGridRef"
            height="calc(100vh - 240px)"
            class="mt-1"
            :data-source="selectedItems"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            column-resizing-mode="widget"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="false"
            :hover-state-enabled="true"
            :customize-columns="customizeColumns"
            @initialized="onInitialized"
            @content-ready="onContentReady"
          >
            <dx-sorting mode="single" />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
            <dx-column data-field="title" caption="Title" :css-class="'max-width-column'" />
            <dx-column data-field="upc" caption="UPC" :width="120" />
            <dx-column data-field="asin" caption="ASIN" :width="120" />
            <dx-column data-field="companyName" caption="Company" />
            <dx-column data-field="storeName" caption="Store" />
            <dx-column data-field="warehouseName" caption="Warehouse" />
            <dx-column data-field="quantity" caption="QTY" alignment="left" :width="100" />
            <dx-column data-field="count" caption="Count" alignment="left" :width="100" cell-template="countTemplate" />
            <template #countTemplate="{data}">
              <dx-util-number-box v-model="data.data.count" :min="1" :max="data.data.quantity" />
            </template>
          </dx-data-grid>
        </div>
    </template>
  </dx-util-popup>
</template>

<script>
import { Notify } from '@robustshell/utils/index'
import Pageable from '@robustshell/components/Grid/pageable'
import inventoryService from '@/http/requests/inventory/inventoryService'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { InventoryMoveReason } from '@/enums'
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import planManagementService from '@/http/requests/plan/planManagementService'

export default {
  mixins: [GridBase],
  props: {
    selectedItems: {
      type: Array,
      required: true,
    },
    itemAction: {
      type: String,
      required: true,
    },
    companyInfo: {
      type: Object,
      required: true,
    },
    stores: {
      type: Array,
      required: true,
    },
    componentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inventoryMoveReasons: Object.values(InventoryMoveReason),
      selectedItemAction: '',
      selectedMoveReason: '',
      selectedStore: '',
      itemActions: [
        { text: 'FBA', value: 'FBA' },
        { text: 'WFS', value: 'WFS' },
        { text: 'FBM', value: 'FBM' },
        { text: 'Return', value: 'RETURN' },
        { text: 'Removal Order', value: 'REMOVAL_ORDER' },
        { text: 'Forwarding', value: 'FORWARDING' },
        { text: 'Storage', value: 'STORAGE' },
        { text: 'Dropship', value: 'DROPSHIP' },
        { text: 'B2B', value: 'B2B' },
      ],
      userPlans: [],
      selectedUserPlan: 0,
      pageable: new Pageable({ pageSize: 500 }),
      moveInventoryPopupTitle: '',
    }
  },
  computed: {
    moveInventoryPopupIns() {
      return this.$refs.moveInventoryRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          // this.dataSourceArr = []
          this.createMoveInventoryPopup()
        }
      },
    },
  },
  methods: {
    createMoveInventoryPopup() {
      this.moveInventoryPopupIns.show()
    },
    onShownMoveInventoryPopup() {
      this.moveInventoryPopupTitle = `Move Inventory From ${this.itemAction} To`

      for (let i = 0; i < this.selectedItems.length; ++i) {
        this.selectedItems[i].count = this.selectedItems[i].quantity
      }
      this.getUserPlans()
    },
    onHiddenMoveInventoryPopup() {
      this.onClose()
    },
    onClose() {
      this.moveInventoryPopupIns.hide()
      this.reset()
      this.$emit('on-item-saved')
    },
    getUserPlans() {
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.warehouseId = this.companyInfo.warehouseId
      PlanSearchFilter.accountNo = this.companyInfo.accountNo
      const searchFilter = PlanSearchFilter.getFilters()
      this.userPlans.splice(0, this.userPlans.length)
      planManagementService.fetchAllUserPlansByQuery(searchFilter, this.pageable.toCreateQueryString())
        .then(result => {
          const data = result.data.content
          if (data.length > 1) {
            this.userPlans.push({ value: 0, text: 'Plans (All)' })
          }
          data.forEach(element => {
            this.userPlans.push({
              value: element.id,
              text: `${element.planCategory.toUpperCase()}: ${element.planName}: ${element.planType}`,
            })
          })
          this.selectedUserPlan = 0
          if (this.userPlans.length === 1) {
            this.selectedUserPlan = this.userPlans[0].value
          }
        })
    },
    reset() {
      this.selectedItemAction = ''
      this.selectedStore = ''
      this.selectedUserPlan = ''
      this.selectedMoveReason = ''
    },
    onSubmit(e) {
      const validationResult = e.validationGroup.validate()
      if (this.selectedItemAction === 'None') {
        Notify.warning('Please select a destination inventory.')
        return
      }
      if (this.selectedStore === 0) {
        Notify.warning('Please select a destination store.')
        return
      }
      if (this.selectedUserPlan === 0) {
        Notify.warning('Please select a plan.')
        return
      }
      if (validationResult.isValid) {
        const moveInventory = {
          accountNo: this.companyInfo.accountNo,
          prevStoreId: this.companyInfo.storeId,
          newStoreId: this.selectedStore,
          warehouseId: this.companyInfo.warehouseId,
          planId: this.selectedUserPlan,
          prevItemAction: this.companyInfo.itemAction,
          newItemAction: this.selectedItemAction,
          moveReason: this.selectedMoveReason,
          items: [],
        }
        this.selectedItems.forEach(item => {
          moveInventory.items.push({
            productId: item.productId,
            availableQuantity: item.quantity,
            quantity: item.count,
          })
        })
        inventoryService.moveInventory(moveInventory).then(result => {
          Notify.success(result.data)
          this.$emit('on-item-saved')
          this.onClose()
          e.validationGroup.reset()
        })
      } else {
        Notify.warning('Please check counts.')
      }
    },
  },
}
</script>

<style lang="scss">
.max-width-column {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
